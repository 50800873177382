<template>
  <v-container fluid fill-height class="pa-0 ma-0 narekomu-back" >
    <div class="login-back people"></div>
    <v-card id="login-card" class="elevation-1   login-card  body-2" >
      <v-toolbar
          color="blue-grey darken-4"
          dark
          flat
          height="80"
      >    
      <v-btn icon  link to="/"><v-icon>{{icons.arrowLeft}}</v-icon></v-btn>
      <v-row justify="center">
        <v-text class="text-h6 mr-6">パスワードの再設定</v-text>
      </v-row>
    </v-toolbar>
      <v-card-text v-if="!isComplete" class="mt-3 pb-1">
        メールアドレスを入力してください。<br>
        パスワード再設定メールを送信します。
        <v-form ref="loginForm" v-model="valid" lazy-validation  @submit.prevent>
          <v-text-field
            outlined
            dense
            name="login"
            label="メールアドレス"
            type="text"
            v-model="email"
            :counter="255"
            :rules="emailRules"
            required
            class="my-1"
          ></v-text-field>
        
        </v-form>
        
      </v-card-text>
      <v-card-text v-else class="mt-3 pb-1">
        パスワード再設定用のメールを送信しました。<br>
        メールのリンクから再設定を行ってください。<br>
        登録されていないメールアドレスには送信されないため、お問い合わせをお願い致します。
       
      </v-card-text>

   
      <div v-if="!isComplete" class="login-btn px-4 pb-4">
        
        <v-btn 
          block 
          color="blue-grey darken-4 white--text"
          :loading="loading"
          :disabled="!valid || email==''"
          @click="sendData"
          >送信</v-btn>
      </div>
      <div v-if="!isComplete" class="pa-4">
        <p style="border-top:1px solid gray  "></p>
        <p class="ma-0  pa-0 text-center text--secondary" >初めてご利用の方</p>
        <v-row justify="center"
          class="mr-2;"
        >
          <v-btn 
            link 
            to="/create_account"
            text
            dense
            id="no-background-hover"
            class="mb-4 mt-2 primary--text text-center"
            >アカウントを作成する</v-btn>
        </v-row>
      </div>
      <div v-else class="pa-4">
        <p style="border-top:1px solid gray  "></p>
         <v-row justify="center" class="mr-2;">
          <v-btn 
            link 
            to="/home"
            text
            dense
            id="no-background-hover"
            class="mb-4 mt-2 primary--text text-center"
            >トップページへ</v-btn>
        </v-row>
      </div>
    </v-card>
   </v-container>
</template>

<script>
import axios from "axios"
import { mdiArrowLeft } from '@mdi/js';
export default {
  name: 'ResetPassword',
  data: () => ({
    icons:{"arrowLeft":mdiArrowLeft},
    loading: false,
    showPassword:false,
    isNoMatch:false,
    isError:false,
    isComplete:false,
    valid:false,
    emailRules: [
      v => !!v || "メールアドレスは必須項目です。",
      v => (v && v.length <= 255) || "メールアドレスは255文字以内で入力してください。",
      v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "メールアドレスの形式が正しくありません。"
    ],
    email: "",
  }),
  methods:{
    //パスワードの変更
    sendData(){
      this.loading = true;
      axios({
        method: "POST",
        url:`${process.env.VUE_APP_URL}password/reset`,
        headers: {"X-Api-Key": process.env.VUE_APP_KEY, "Content-Type":"application/json" },
        data:{
          "UserId":this.email
        },
      }).then((response) => {
        if(response.data.results=="complete"){
            this.isComplete=true;
            this.loading - false;
        }else{
          this.loading = false;
          this.isError = true;
        }
      }).catch(() => {
        // console.log(reason)
        this.loading = false;
        this.isError = true;
      });
    },
  }


}
</script>

<style  scoped>
    #login-card {
        width:90%;
        max-width:350px;
        margin: auto;
        z-index:2000;
    }

    .narekomu-back{
        /* background: url("../assets/login-narekomuvr.jpg"); */
        background-size: cover;
        background-position: center center;
        width: 100%;
        background-color: white;
    }

    #no-background-hover::before {
      background-color: transparent !important;
    }

    .login-back{
      position:absolute;
       left: 0;
      right: 0;
      margin: auto;
      width:100%;
      max-width:1400px;
      height:100%;
    }

    .people{
      animation: fadein 0.4s ease-out forwards;
      background: url("~@/assets/people.webp") no-repeat center center;
      background-size:auto 100%;
    }

    .male{
      position:absolute;
  
      right:0%;
      height:100%;
      max-width:850px;
      min-width:100px;
      bottom:0;
      z-index:1000;
      object-fit:cover;
      object-position:right top;
    }

    .female{
      position:absolute;
      height:100%;
      left:0%;
      bottom:0;
      z-index:900;
      max-width:850px;
      min-width:100px;
      object-fit:cover;
      object-position:left top;
    }


    @media screen and (max-width: 1000px) {
      .male{
         position:absolute;
        height:100%;
        left:0;
        max-width:850px;
        min-width:100px;
        width:50%;
        bottom:0;
        z-index:1000;
        object-fit:cover;
        object-position:50% top;
      }

      .female{
        position:absolute;
        height:100%;
        left:50%;
        bottom:0;
       
        z-index:900;
        max-width:850px;
        min-width:100px;
        width:60%;
        object-fit:cover;
        object-position:50% top;
      } 

    }

</style>